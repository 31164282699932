import React, { useState } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import type { CmdbObject } from '../../../rest/cmdb-object-picker';
import { MemoizedInsightIcon } from '../fields/cmdb-object-picker/insight-icon';
import { AttributeList } from './attribute-list';
import { ExpandCollapseToggle } from './expand-collapse-toggle';
import messages from './messages';

interface Props {
    cmdbObject: CmdbObject | null;
}

const CmdbObjectCard = ({ intl, cmdbObject }: Props & WrappedComponentProps) => {
    const [expanded, setExpanded] = useState(false);
    if (cmdbObject === null) {
        return intl.formatMessage(messages.error);
    }

    const renderChevronButton = () => {
        if (cmdbObject.attributes.length === 0) {
            return null;
        }
        const buttonLabel = intl.formatMessage(expanded ? messages.collapseLabel : messages.expandLabel);
        return (
            <ExpandCollapseToggle isExpanded={expanded} label={buttonLabel} onClick={() => setExpanded(!expanded)} />
        );
    };

    return (
        <Box xcss={containerStyles}>
            <Inline alignBlock="center">
                <MemoizedInsightIcon {...cmdbObject.avatar.mediaClientConfig} />
                <Box xcss={labelStyles}>{cmdbObject.label}</Box>
                {renderChevronButton()}
            </Inline>
            {expanded && (
                <Box xcss={attributesListContainerStyles}>
                    <AttributeList attributes={cmdbObject.attributes} />
                </Box>
            )}
        </Box>
    );
};
export default injectIntl(CmdbObjectCard);

const containerStyles = xcss({
    position: 'relative',
    width: '100%',
    maxWidth: '600px',
    border: '1px solid',
    borderColor: 'color.border',
    borderRadius: 'border.radius',
    marginBottom: 'space.100',
    padding: 'space.100',
    boxSizing: 'border-box',
});

const labelStyles = xcss({
    font: 'font.heading.xsmall',
    color: 'color.text.subtle',
    margin: 'space.0',
    paddingLeft: 'space.100',
    flexGrow: 1,
    minWidth: 'space.0',
    overflowWrap: 'break-word',
});

const attributesListContainerStyles = xcss({
    marginTop: 'space.200',
});
