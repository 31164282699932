import type { FetchFunction } from 'relay-runtime';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { getEnv, EnvType } from '@atlassian/help-center-common-util/env';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';

const fetchQuery: FetchFunction = ({ operationKind, id, name }, variables) => {
    const isJsmScaleTestAggCheckEnabled = FeatureGates.checkGate('jsm_scale_60k_testing_in_help_center');
    const AGG_BASE_PATH = id ? `/gateway/api/graphql/pq/${id}` : '/gateway/api/graphql';
    let AGG_PATH = process.env.AGG_BRANCH_SLUG
        ? `${AGG_BASE_PATH}/${process.env.AGG_BRANCH_SLUG}/graphql`
        : AGG_BASE_PATH;

    if (isJsmScaleTestAggCheckEnabled && getEnv().environment !== EnvType.PROD) {
        const AGG_BRANCH_SLUG = 'branch-issue-remove-rate-limit';
        AGG_PATH = id
            ? `/gateway/api/graphql/${AGG_BRANCH_SLUG}/graphql/pq/${id}`
            : `/gateway/api/graphql/${AGG_BRANCH_SLUG}/graphql`;
    }

    const body = JSON.stringify({
        variables,
        operationName: name,
    });

    return fetchJson(AGG_PATH, {
        body,
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...(operationKind === 'mutation' && {
                'X-ExperimentalApi': 'help-object-store-agg-beta, HelpCenterReorderTopics',
            }),
        },
    });
};

export default fetchQuery;
