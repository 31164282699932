import {
    isPremiumSLAErrorFilterEnabled,
    isFilterNullTraceIdFromGraphqlCallsEnabled,
    isFilterNetworkErrorsWithTraceIdFromSentryEnabled,
} from 'feature-flags';
import type { GraphQLResponseWithoutData } from 'relay-runtime';
import type { TFetchError } from './types';

const NO_CONTENT = 204;
const NETWORK_ERROR_MESSAGE = 'Failed to fetch';
const EMPTY_TRACE_ID_ERROR_MESSAGE = 'Failed to fetch got empty traceId';
const NETWORK_ERROR_CODE = 0;
interface GraphqlExtensionGateway {
    request_id: string;
}
interface PayloadExtensions {
    [key: string]: unknown;
}
interface GraphqlErrorsWithExtensions {
    message: string;
    extensions?: PayloadExtensions;
}
export class FetchError extends Error {
    status: number;
    filterFromSentry?: boolean;
    constructor(message: string, status: number, filterFromSentry?: boolean) {
        super(message);
        this.status = status;
        this.filterFromSentry = filterFromSentry;
    }
}

export const fetchJson = <TResponse,>(url: string, params: RequestInit) => {
    return fetch(url, params)
        .then(handleErrors)
        .then<TResponse>(processResponseFromServer)
        .then(handleGraphQlErrors)
        .catch(handleNetworkErrors);
};

const handleNetworkErrors = (err: Error) => {
    if (err instanceof TypeError) {
        throw new FetchError(NETWORK_ERROR_MESSAGE, NETWORK_ERROR_CODE);
    }
    throw err;
};

const handleErrors = (response: Response) => {
    if (!response.ok) {
        const traceId = response.headers.get('atl-traceid');
        // If the traceId is null, it indicates that the request never reached the Atlassian network. In such cases, we should not throw an error. This situation may occur if the request has been intercepted by a firewall or another proxy server. For more details, refer to the discussion in the following Slack thread: https://atlassian.slack.com/archives/C06Q6AC22MV/p1711342785804189?thread_ts=1711335142.173669&cid=C06Q6AC22MV
        if (isFilterNullTraceIdFromGraphqlCallsEnabled() && !traceId) {
            // If the request does not have a traceId we treat it as a network error.
            throw new FetchError(EMPTY_TRACE_ID_ERROR_MESSAGE, NETWORK_ERROR_CODE);
        }
        if (isFilterNetworkErrorsWithTraceIdFromSentryEnabled() && traceId) {
            // We filter out network errors with traceId from Sentry as they are not actionable and can be investigated from Splunk.
            throw new FetchError(`ErrorCode: ${response.status}, traceId: ${traceId || 'null'}`, response.status, true);
        }
        throw new FetchError(`ErrorCode: ${response.status}, traceId: ${traceId || 'null'}`, response.status);
    }
    return response;
};

const processResponseFromServer = (response: Response) => {
    if (response.status === NO_CONTENT) {
        return Promise.resolve(null);
    }
    return response.json();
};

const handleGraphQlErrors = <TResponse,>(response: TResponse) => {
    if (
        response &&
        typeof response === 'object' &&
        // @ts-ignore TS(2339) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Property 'hasOwnProperty' does not exist on type '... Remove this comment to see the full error message
        response.hasOwnProperty('errors') &&
        // @ts-ignore TS(2339) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Property 'hasOwnProperty' does not exist on type '... Remove this comment to see the full error message
        response.hasOwnProperty('extensions')
    ) {
        // throw error from graphql API
        const graphQlResponse = response as unknown as Omit<GraphQLResponseWithoutData, 'errors'> & {
            errors: GraphqlErrorsWithExtensions[];
        };
        const traceId = (graphQlResponse.extensions?.gateway as GraphqlExtensionGateway)?.request_id;
        if (isFilterNetworkErrorsWithTraceIdFromSentryEnabled() && traceId) {
            throw new FetchError(
                `ErrorCode: ${graphQlResponse.errors?.[0]?.message}, traceId: ${traceId}`,
                Number(graphQlResponse.errors?.[0]?.extensions?.statusCode) || 500,
                true
            );
        }
        throw new FetchError(
            `Error: ${graphQlResponse.errors?.[0]?.message}, traceId: ${traceId}`,
            Number(graphQlResponse.errors?.[0]?.extensions?.statusCode) || 500
        );
    }
    return response;
};

export const isFetchNetworkError = (err: TFetchError) => {
    return err.status === NETWORK_ERROR_CODE;
};

export const isFetchClientError = (err: TFetchError) => {
    if (isPremiumSLAErrorFilterEnabled()) {
        return err.status < 500;
    }
    return err.status >= 400 && err.status < 500;
};

export const isFetchNetworkOrClientError = (err: TFetchError) => {
    return isFetchNetworkError(err) || isFetchClientError(err);
};
