/**
 * Define feature flag here as constants.
 *
 * export const KB_ENHANCEMENTS = 'jsd.px.kb-enhancements';
 *
 * Use them in react components.
 * import { KB_ENHANCEMENTS } from 'feature-flags';
 * import { FeatureFlag } from '@atlassian/help-center-common-component/feature-flag';
 *
 * <FeatureFlag name={KB_ENHANCEMENTS}>
 *  I will be shown in true :-)
 * </FeatureFlag>
 *
 * ========== NOTE ===================
 * BE CAREFUL ADDING FEATURE FLAGS, IF YOU RENAME THEM IT WILL CHANGE THE ANALYTIC CONTEXT
 * NAME AND NO LONGER SEND THE SAME CONTEXT ATTRIBUTE NAME.
 * ===================================
 */

import FeatureGates from '@atlaskit/feature-gate-js-client';
// Be careful when using these constants directly. In most cases, you should use the function.
export const RICH_TEXT_AREA_FIELD = 'jsd-use-adf-default-value-for-textarea';
export const ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES = 'jsm-enhanced-render-exp-for-knowledge-articles';

const ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB = 'make-kb-articles-open-in-same-tab';

export const REDIRECT_TO_LOGIN_PAGE_ON_INVALID_TOKEN_FOR_HOME_PAGE = 'redirect_to_login_for_hc_home_page_un_authorized';
export const UNIFIED_HELP_REPORTING_PAGE = 'help_center_reporting_route';
export const UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS = 'unified-help-add-filter-for-customer-accounts';

export const BASIC_HC_CALLS_JIRA = 'basic_hc_calls_jira';

const JCSM_CS_MASTER_FLAG = 'jcs_master_flag';
const BERRY_4490_ENTITLEMENT_LOG = 'berry-4490-entitlement-portal-log';
const CSM_ACCESS_CONTROL_SPLIT = 'csm_access_control_split';
const CSM_PREMIUM_SLA_DISABLED_FLAG = 'jsm_csm_premium_sla_config';
const CSM_CUSTOM_PAGES_ENABLED = 'enable_custom_pages_csm';
const AI_WIDGET_TEAM25 = 'berry-5094-ai-widget-support-website-team25';
const JCS_FORMS_RESILIENCY = 'jcs_forms_resiliency';
const CSM_CONTACT_US_SECTION = 'jcs_contact_us_section';

export const ENABLE_HC_LAYOUT_GRANULAR_INTERACTION = 'enable-hc-layout-granular-interactions';
export const JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS = 'anonymous_virtual_agent_access';

export const ENABLE_REACT_18_CREATEROOT = 'enable_react-18';
export const DONT_USE_HYDRATE_SSR = 'dont_use_hydrate_in_portal_ssr';
export const FIX_EXTERNAL_LINK_BUG = 'external_link_click_event_bug_fix';

const FIX_RELAY_ID_CONFLICT_IN_HELP_CENTER = 'fix_relay_id_conflict_in_help_center';
const A11Y_FIX_FOR_REMOVE_ATTACHMENT_BUTTON = 'a11y_fix_for_remove_attachment_button';
const ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE = 'advanced_help_center_customization_edit_page';
const UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD = 'update_text_field_package_version_for_input_field';
const REMOVE_TRANSLATIONS_FALLBACK = 'remove_translations_fallback';
const TUNING_RETRY_MECHANISM_FOR_MODELS_ENDPOINT = 'tuning_model_retries';
const FILTER_PREMIUM_SLA_ERRORS = 'filter_premium_sla_errors';
const HELP_CENTER_DROPDOWN_REDESIGN = 'help_center_dropdown_redesign';
const CUSTOMISATION_BUTTON_CHANGE_BOARDING = 'enable_customisation_button_change_boarding';
const PROJECT_MAPPING_ENABLED_IN_MULTI_HC = 'enable_project_mapping_for_help_center';
const CANCLE_APPROVAL_ACTIVITY_HISTORY = 'start-1399-surface-cancel-approval-into-activity';
const SUMMARY_FIELD_A11Y_FIXES = 'summary_field_a11y_fixes';
const FILTER_NULL_TRACE_ID_FROM_GRAPHQL_CALLS = 'filter_null_trace_id_from_graphql_calls';
const USE_NEW_EDITOR_CARD_PROVIDER = 'use_new_editor_card_provider';
const REMOVE_SLASH_FROM_HOMEPAGE_ROUTE = 'remove_slash_from_homepage_routes';
const REMOVE_NON_CRITICAL_RESOURCE_FROM_VIEW_HOMEPAGE_ROUTE = 'remove_non_critical_resource_from_hc_home_route';
const ENABLE_CROSS_SITE_CONFLUENCE = 'enable-cross-site-confluence';
const PROFORMA_TTVC_UPDATE_PACKAGE_ENABLED = 'measure_proforma_ttvc_impact';
const HANDLE_FOLLOW_UP_QUERIES_ON_VA = 'handling_followupquery_on_feedback_response';
const JSM_SEARCH_UI_REDESIGN = 'jsm_help_center_search_ui_redesign';
const REMOVE_CREATE_REQUEST_REDUNDANT_MODELS = 'jsm-create-request-redundant-models-fe';
const VA_OMNICHANNEL_ENABLED = 'va_on_hc_and_portal_toggle_rest_or_graphql';
const ENABLE_RELAY_SUBSCRIPTIONS = 'enable_subscription_in_relay';
const JSM_SEARCH_UI_REDESIGN_CONTENT = 'jsm_help_center_search_ui_redesign_content';
const JSM_LOCK_SCIM_MANAGED_CUSTOMER_AND_ORG = 'jsm_lock_scim_managed_customer_and_org';
const CONSUME_LOOSELY_LAZY_MANIFEST_IN_SSR = 'consume_loosely_lazy_manifest_in_ssr';
const DEFAULT_HC_USING_GQL_BRANDING = 'default_hc_using_gql_branding';
const MOVE_ADMIN_SCREENS_TO_HC_MANAGEMENT_ROUTER = 'move-admin-screens-to-management-router';
const PROJECT_MAPPING_CHANGE_BOARDING = 'project_mapping_change_boarding';
export const HELP_CENTER_REFRESH_FLAG = 'help_center_refresh';
const GOOGLE_TRANSLATE_ISSUE_WORKAROUND = 'google_translate_issue_workaround';
const REMOVE_EMOJI_FROM_SSR = 'help_desk_remove_emoji_from_ssr';
const ENABLE_FORGE_APPS_IN_JSM_PHASE_1 = 'enable_forge_apps_in_jsm_layout_builder_phase_1';
const UNABLE_ANALYTICS_FAILURE_EVENT_WITH_ERROR = 'update_analytics_failure_event_with_error';
const REMOVE_FLEXIHC_BETA_TAG_FOR_GA = 'remove_flexihc_beta_tag_for_ga';
const FIX_PROJECT_MAPPING_P1_BUGS = 'fix_project_mapping_p1_bugs';
const A11Y_FIX_FOR_PORTALS_GROUP_HEADING = 'a11y_fix_for_portal_groups_heading_';
const A11Y_CHANGE_FOR_VIEW_ANNOUNCEMENT = 'a11y_change_for_view_announcement';
export const EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE = 'sptn-1697-email-verified-signal-on-customers-page';
const NEW_TEXT_FIELD_ENABLED_FOR_FORM = 'new-text-field-enabled-for-form';
const COMMENT_EDITOR_RERENDER_ON_SUBMIT = 'comment_editor_rerender_on_submit';
const SUMMARY_FIELD_DEBOUNCED = 'summary-field-debounced';
const DARKER_BORDER_FOR_RICH_TEXT_AREA = 'editor-darker-border';
const HELP_CENTER_CUSTOM_PAGES = 'help_center_custom_pages_ui';
const SEND_GASV3_ANALYTICS_FROM_EDITOR = 'send_gasv3_analytics_from_editor';
const USE_REACT_SUSPENSE_IN_THE_LOGO = 'use_react_suspense_in_the_logo';
const HOMEPAGE_ERROR_HAS_STATUS_CODE = 'homepage_error_has_status_code';
const REACT_18_LAZY_ENABLED = 'react_18_lazy_enabled';
export const ENABLE_INCREASED_FLEXIBILITY = 'enable_increased_flexibility';

export const DAYONE_FOCAQ_EXPERIMENT = 'jsm_1d_fo_core_actions';
export const DAYONE_FOCAQ_FEATURE_GATE = 'jsm_1d_fo_core_actions_gate';
export const STRING_LENGTH_VALIDATION_WITHOUT_TRIM = 'string_length_validation_without_trim';
export const ENABLE_FOLLOWUP_QUESTIONS_ON_PORTAL = 'enable_followup_questions_on_omni_channel_portal';
export const CORTOPS_EXPERIMENT = '_connecting_request_types_on_portal';
const JSM_GDAY_FEATURES = 'jsm_gday_kb_features';
const ENABLE_ISOMORPHIC_ERROR_HANDLING = 'enable_isomorphic_error_handling';
const FETCHJSON_USED_IN_CMDB_OBJECT_FIELD = 'fetchjson_used_in_cmdb_object_field';
const COOKIE_MONSTER_3 = 'help_center_cookie_monster_3';
const REQUEST_DETAILS_TOOLTIP_PARENT_CONTAINER = 'request_details_tooltip_parent_container';
const VISUAL_REFRESH_ONBOARDING = 'visual-refresh-changeboarding';
const INCREASED_FLEXIBILITY_CHANGEBOARDING = 'increased-flexibility-changeboarding';
const REQUEST_CREATE_DROPDOWN_INITIAL_OPTIONS_CALL = 'request_create_dropdown_initial_options_call';
const FILTER_QUERY_ERROR_FROM_SENTRY = 'filter_query_error_from_sentry';
const PRAGMATIC_DND_MIGRATION_LAYER = 'pragmatic_migration_layer';
const HIDE_KB_COLUMN_PROJECT_BREAKDOWN = 'hc_vsa_settings_hide_kb_article_column';
const VSA_SETTINGS_PERFORMANCE_IMPROVEMENTS = 'hc_vsa_settings_performance_improvements';
const FILTER_NETWORK_ERRORS_WITH_TRACE_ID = 'filter-network-errors-with-trace-id';
const TOTAL_ARTICLE_COUNT = 'hc_show_kb_total_article_count';

export const isA11yFixForRemoveAttachmentButtonEnabled = () =>
    FeatureGates.checkGate(A11Y_FIX_FOR_REMOVE_ATTACHMENT_BUTTON);
export const ENABLE_HELPCENTER_ARI_AI_ANALYTICS_METADATA = 'enable_helpcenter_ari_in_ai_analytics_metadata';

const ENABLE_JIRA_FIELD_HANDLING = 'enable_jira_field_handling_in_omnichannel';

export const isGranularLayoutInteractionTestEnabled = () =>
    FeatureGates.checkGate(ENABLE_HC_LAYOUT_GRANULAR_INTERACTION);

export const is1DFOCAQExperimentEnabled = () =>
    FeatureGates.getExperimentValue<string>(DAYONE_FOCAQ_EXPERIMENT, 'cohort', 'not-enrolled');

export const is1DFOCAQFeatureGateEnabled = () => FeatureGates.checkGate(DAYONE_FOCAQ_FEATURE_GATE);

export const isCORTOPSExperimentEnabled = () => {
    return FeatureGates.getExperimentValue<string>(CORTOPS_EXPERIMENT, 'cohort', 'not-enrolled');
};

export const isEmailVerifiedSignalEnabled = () => {
    return FeatureGates.checkGate(EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE);
};

// TODO: remove this code, we are marking this true given timeline for LD Deprecation
export const isWrmOptimizationsEnabled = () => true;

export const isRichTextAreaEnabled = () => FeatureGates.checkGate(RICH_TEXT_AREA_FIELD);

export const isEnhanceRenderExperienceForKnowledgeArticlesEnabled = () =>
    FeatureGates.checkGate(ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES);

export const isRedirectToLoginPageOnInvalidTokenForHomePage = () =>
    FeatureGates.checkGate(REDIRECT_TO_LOGIN_PAGE_ON_INVALID_TOKEN_FOR_HOME_PAGE);

export const islooselyLazyJsErrorsToSentryEnabled = () =>
    FeatureGates.checkGate('throw_lazy_error_boundary_js_errors_to_sentry');

/*
 * Cleaning up the LD feature flag 'jsm-portal-custom-theming'
 * https://app.launchdarkly.com/jira/production/features/jsm-portal-custom-theming
 * Custom themes were not rolled out.
 *
 * */
export const isCustomThemingEnabled = () => false;

export const isUnifiedHelpReportingPageEnabled = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_REPORTING_PAGE);
};

export const isProjectMappingInHelpCenterEnabled = () => {
    return FeatureGates.checkGate(PROJECT_MAPPING_ENABLED_IN_MULTI_HC);
};

export const isUnifiedHelpAddFilterForCustomerAccounts = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS);
};

export const isFixRelayIdConflictInHelpCenter = () => {
    return FeatureGates.checkGate(FIX_RELAY_ID_CONFLICT_IN_HELP_CENTER);
};

// Virtual Service Agent (JSM Flux) Feature flags
export const isJSMVirtualAgentAnonymousAccessEnabled = () => FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS);

// JSM Boysenberry feature flags
export const isJsmCsMasterFlagEnabled = () => {
    return FeatureGates.checkGate(JCSM_CS_MASTER_FLAG);
};

export const isJcsFormsResiliencyEnabled = () => {
    return FeatureGates.checkGate(JCS_FORMS_RESILIENCY);
};

export const isEntitlementLogEnabled = () => {
    return FeatureGates.checkGate(BERRY_4490_ENTITLEMENT_LOG);
};

export const isCsmAccessControlSplitEnabled = () => {
    return FeatureGates.checkGate(CSM_ACCESS_CONTROL_SPLIT);
};

export const isAiWidgetTeam25Enabled = () => {
    return FeatureGates.checkGate(AI_WIDGET_TEAM25);
};

// CSM Premium SLA disabled flag
export const isCsmPremiumSlaDisabledFlagEnabled = () => {
    return FeatureGates.checkGate(CSM_PREMIUM_SLA_DISABLED_FLAG);
};

// CSM Kalari feature flags
export const isEditLayoutEnabledForCSM = () => {
    return FeatureGates.checkGate('enable_edit_layout_csm');
};

export const isJcsprojectTypeM3Enabled = () => {
    return FeatureGates.checkGate('jcs_project_type_m3');
};

export const isCsmCustomPagesEnabled = () => {
    return FeatureGates.checkGate(CSM_CUSTOM_PAGES_ENABLED);
};

export const isCsmContactUsSectionEnabled = () => {
    return FeatureGates.checkGate(CSM_CONTACT_US_SECTION);
};

export const isDefaultHCCallsJiraEnabled = () => {
    return FeatureGates.checkGate(BASIC_HC_CALLS_JIRA);
};

export const isSanitizationOfPortalDescriptionToRemoveTabIndexEnabled = () => {
    return FeatureGates.checkGate('sanitize_portal_card_description_tabi_index');
};

export const isCrossSiteConnieConnectionEnabled = () => {
    return FeatureGates.checkGate(ENABLE_CROSS_SITE_CONFLUENCE);
};

export const isKbArticlesSameTabRedirectionEnabled = () => {
    return FeatureGates.checkGate(ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB);
};

export const isAdvancedCustomizationEnabled = () => {
    return FeatureGates.checkGate('advanced_help_center_customization');
};

export const isAdvancedCustomizationEditPageEnabled = () => {
    return FeatureGates.checkGate(ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE);
};

export const isNewTextFieldEnabled = () => {
    return FeatureGates.checkGate(UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD);
};

export const isUseReact18CreateRootEnabled = () => {
    return FeatureGates.checkGate(ENABLE_REACT_18_CREATEROOT);
};

export const isReactHydrateDisabledInSSR = () => {
    return FeatureGates.checkGate(DONT_USE_HYDRATE_SSR);
};

export const fixExternalLinkBug = () => {
    return FeatureGates.checkGate(FIX_EXTERNAL_LINK_BUG);
};

export const isRemoveTranslationsFallbackEnabled = () => {
    return FeatureGates.checkGate(REMOVE_TRANSLATIONS_FALLBACK);
};

export const isPortalCardMarkupFixEnabled = () => {
    return FeatureGates.checkGate('fix_portal_card_markup');
};

export const isHelpCenterNewDropdownEnabled = () => {
    return FeatureGates.checkGate(HELP_CENTER_DROPDOWN_REDESIGN);
};

export const isCustomiseDropDownChangeBoardingEnabled = () => {
    return FeatureGates.checkGate(CUSTOMISATION_BUTTON_CHANGE_BOARDING);
};

export const isFilterNullTraceIdFromGraphqlCallsEnabled = () => {
    return FeatureGates.checkGate(FILTER_NULL_TRACE_ID_FROM_GRAPHQL_CALLS);
};

export const isPremiumSLAErrorFilterEnabled = () => {
    return FeatureGates.checkGate(FILTER_PREMIUM_SLA_ERRORS);
};

export const isMoveToManagementRouterEnabled = () => {
    return FeatureGates.checkGate(MOVE_ADMIN_SCREENS_TO_HC_MANAGEMENT_ROUTER);
};

export const isRetryMechanismTuningEnabled = () => {
    return FeatureGates.checkGate(TUNING_RETRY_MECHANISM_FOR_MODELS_ENDPOINT);
};

export const isCancelApprovalActivityStreamEnabled = () => {
    return FeatureGates.checkGate(CANCLE_APPROVAL_ACTIVITY_HISTORY);
};

export const isNewSummaryFieldA11yEnabled = () => {
    return FeatureGates.checkGate(SUMMARY_FIELD_A11Y_FIXES);
};

export const isUseNewEditorCardProviderEnabled = () => {
    return FeatureGates.checkGate(USE_NEW_EDITOR_CARD_PROVIDER);
};

export const isA11yFixForPortalsGroupHeadingEnabled = () => {
    return FeatureGates.checkGate(A11Y_FIX_FOR_PORTALS_GROUP_HEADING);
};

export const isProformaTTVCUpdateMeasureEnabled = () => {
    return FeatureGates.checkGate(PROFORMA_TTVC_UPDATE_PACKAGE_ENABLED);
};

export const isVAFollowupQueryOnlyForPositiveResponseEnabled = () => {
    return FeatureGates.checkGate(HANDLE_FOLLOW_UP_QUERIES_ON_VA);
};

export const isSlashOptionalInHelpCenterHomeRoute = () => {
    return FeatureGates.checkGate(REMOVE_SLASH_FROM_HOMEPAGE_ROUTE);
};

export const isRemoveNonCriticalResourceFromViewHomePageRouteEnabled = () => {
    return FeatureGates.checkGate(REMOVE_NON_CRITICAL_RESOURCE_FROM_VIEW_HOMEPAGE_ROUTE);
};

export const isSearchUIRedesignEnabled = () => {
    return FeatureGates.checkGate(JSM_SEARCH_UI_REDESIGN);
};

export const isRemoveRedundantModelsCreateRequest = () => {
    return FeatureGates.checkGate(REMOVE_CREATE_REQUEST_REDUNDANT_MODELS);
};

export const isOmniChannelEnabled = () => {
    return FeatureGates.checkGate(VA_OMNICHANNEL_ENABLED);
};

export const enableRelaySubscriptions = () => {
    return FeatureGates.checkGate(ENABLE_RELAY_SUBSCRIPTIONS);
};

export const isSearchUIRedesignContentEnabled = () => {
    return FeatureGates.checkGate(JSM_SEARCH_UI_REDESIGN_CONTENT);
};

export const isVAOnHelpCenterGAEnabled = () => {
    return FeatureGates.checkGate('va_on_help_center_ga');
};

export const isConsumeLooselyLazyManifestInSSREnabled = () => {
    return FeatureGates.checkGate(CONSUME_LOOSELY_LAZY_MANIFEST_IN_SSR);
};

export const isJsmScimLockEnabled = () => {
    return FeatureGates.checkGate(JSM_LOCK_SCIM_MANAGED_CUSTOMER_AND_ORG);
};

export const isMigrateToAnalyticsBridgeEnabled = () => {
    return FeatureGates.checkGate('jsm-portal-migrate-to-analytics-bridge');
};

export const isDefaultHCUsingGqlBrandingCalls = () => {
    return FeatureGates.checkGate(DEFAULT_HC_USING_GQL_BRANDING);
};

export const isProjectMappingChangeBoardingEnabled = () => {
    return FeatureGates.checkGate(PROJECT_MAPPING_CHANGE_BOARDING);
};

export const isUseHelpCenterRefresh = () => {
    return FeatureGates.checkGate(HELP_CENTER_REFRESH_FLAG);
};

export const isUseGoogleTranslateIssueWorkaround = () => {
    return FeatureGates.checkGate(GOOGLE_TRANSLATE_ISSUE_WORKAROUND);
};

export const isRemoveEmojiFromSSR = () => {
    return FeatureGates.checkGate(REMOVE_EMOJI_FROM_SSR);
};

// Ditto FCF (start)

export const isForgeCustomFieldsOnPortalRequestEnabled = () =>
    FeatureGates.checkGate('forge_custom_fields_on_jsm_portal_create');

// Ditto FCF (end)

export const isForgeAppsInJSMPhase1Enabled = () => FeatureGates.checkGate(ENABLE_FORGE_APPS_IN_JSM_PHASE_1);

export const isUiModificationsSupportEnabled = () => FeatureGates.checkGate('ditto_uim_-_jsm_support_main');

export const isUiModificationsExtensionContextChangeEnabled = () =>
    FeatureGates.checkGate('update_uim_extensioncontext_in_help_center');

export const isUiModificationsForgeContextTokenEndpointEnabled = () =>
    FeatureGates.checkGate('use_jira_forge_context_token_for_uim_on_jsm');

export const isForgeOnConsentInitializationPropEnabled = () =>
    FeatureGates.checkGate('enable_forge_onconsentinitialization_prop_in_jsm');

export const isUpdateAnalyticsFailureEventWithError = () =>
    FeatureGates.checkGate(UNABLE_ANALYTICS_FAILURE_EVENT_WITH_ERROR);

export const isA11yChangeForViewAnnouncementEnabled = () => {
    return FeatureGates.checkGate(A11Y_CHANGE_FOR_VIEW_ANNOUNCEMENT);
};

export const isBetaTagRemovedForFlexiHCGA = () => {
    return FeatureGates.checkGate(REMOVE_FLEXIHC_BETA_TAG_FOR_GA);
};

export const isProjectMappingP1BugsEnabled = () => {
    return FeatureGates.checkGate(FIX_PROJECT_MAPPING_P1_BUGS);
};

export const isStringLengthValidationWithoutTrimEnabled = () => {
    return FeatureGates.checkGate(STRING_LENGTH_VALIDATION_WITHOUT_TRIM);
};

export const isFollowupEnabledForPortal = () => {
    return FeatureGates.checkGate(ENABLE_FOLLOWUP_QUESTIONS_ON_PORTAL);
};

export const isHelpcenterAriEnabledInAnalyticsMetaData = () => {
    return FeatureGates.checkGate(ENABLE_HELPCENTER_ARI_AI_ANALYTICS_METADATA);
};

export const isNewTextFieldEnabledForForm = () => {
    return FeatureGates.checkGate(NEW_TEXT_FIELD_ENABLED_FOR_FORM);
};

export const isAiAnswer24HourTimeoutEnabled = () => {
    return FeatureGates.checkGate('ai_answer_default_timeout_to_24_hours');
};

export const shouldCommentEditorRerenderOnSubmit = () => {
    return FeatureGates.checkGate(COMMENT_EDITOR_RERENDER_ON_SUBMIT);
};

export const isSummaryFieldDebounced = () => {
    return FeatureGates.checkGate(SUMMARY_FIELD_DEBOUNCED);
};

export const isDarkerBorderEnabledForRichTextArea = () => {
    return FeatureGates.checkGate(DARKER_BORDER_FOR_RICH_TEXT_AREA);
};

export const isSendGasv3AnalyticsFromEditorEnabled = () => {
    return FeatureGates.checkGate(SEND_GASV3_ANALYTICS_FROM_EDITOR);
};

export const isCustomPagesUiEnabled = () => {
    return FeatureGates.checkGate(HELP_CENTER_CUSTOM_PAGES);
};

export const isUseReactSuspenseInTheLogoEnabled = () => {
    return FeatureGates.checkGate(USE_REACT_SUSPENSE_IN_THE_LOGO);
};

export const isKbRelatedSentryAlertsFixEnabled = () => {
    return FeatureGates.checkGate('fix-kb-related-sentry-alerts');
};

export const isJiraFieldHandlingEnabled = () => {
    return FeatureGates.checkGate(ENABLE_JIRA_FIELD_HANDLING);
};

export const isIncreasedFlexibilityEnabled = () => {
    return FeatureGates.checkGate(ENABLE_INCREASED_FLEXIBILITY);
};

export const shouldAddUFOHoldsToRequestDetailPage = () => {
    return FeatureGates.checkGate('add_ufo_holds_to_request-detail_page');
};

export const isElementRendererSplitEnabled = () => {
    return FeatureGates.checkGate('enable_element_renderer_split');
};

export const isGdayFeaturesForKbArticlesEnabled = () => {
    return FeatureGates.checkGate(JSM_GDAY_FEATURES);
};

export const isProformaGranularPermissionsEnabled = () => {
    return FeatureGates.checkGate('granular_permissions_on_forms_m1');
};

export const isOmnichannelEnabledForHC = () => {
    return FeatureGates.checkGate('enable_omni_channel_for_helpcenter');
};

export const isIsomorphicErrorHandlingEnabled = () => {
    return FeatureGates.checkGate(ENABLE_ISOMORPHIC_ERROR_HANDLING);
};

export const isOmnichannelCombinedAPIEnabledForHC = () => {
    return FeatureGates.checkGate('enable_combinedapi_flow_in_helpcenter');
};

export const isClearRequestFormOnUnmount = () => {
    return FeatureGates.checkGate('clear_request_form_on_unmount');
};

export const isItsmIssueRenamingEnabled = () => {
    return FeatureGates.checkGate('itsm-issue-renaming');
};

export const isOmnichannelGreetingAPIEnabled = () => {
    return FeatureGates.checkGate('jsm_chat_orchestrator_greeting_api_enabled');
};

export const isIsomorphicDompurifyLibUsed = () => {
    return FeatureGates.checkGate('isomorphic-dompurify-enabled');
};

export const isFetchJsonUsedInCmdbObjectField = () => {
    return FeatureGates.checkGate(FETCHJSON_USED_IN_CMDB_OBJECT_FIELD);
};

export const isLocalStorageEnabledFromBrowserStorageControl = () => {
    return FeatureGates.checkGate(COOKIE_MONSTER_3);
};

export const isHomepageErrorHasStatusCodeEnabled = () => {
    return FeatureGates.checkGate(HOMEPAGE_ERROR_HAS_STATUS_CODE);
};

export const isOmnichannelSubscriptionErrorpageEnabled = () => {
    return FeatureGates.checkGate('enable_omnichannel_subscription_error_page');
};

export const isReact18LazyEnabled = () => {
    return FeatureGates.checkGate(REACT_18_LAZY_ENABLED);
};

export const isCmdbFieldWithAttributesEnabled = () => {
    return FeatureGates.checkGate('jsm_assets_custom_field_request_detail_attributes');
};

export const isRequestDetailsToolTipWrappedUnderContainer = () => {
    return FeatureGates.checkGate(REQUEST_DETAILS_TOOLTIP_PARENT_CONTAINER);
};

export const isVisualRefreshChangeboardingEnabled = () => {
    return FeatureGates.checkGate(VISUAL_REFRESH_ONBOARDING);
};

export const isIncreasedFlexibilityChangeboardingEnabled = () => {
    return FeatureGates.checkGate(INCREASED_FLEXIBILITY_CHANGEBOARDING);
};

export const isThemeStatePassed = () => {
    return FeatureGates.checkGate('pass-theme-state-in-ep');
};

export const isRequestCreateSkipDropdownInitialOptionsCallEnabled = () => {
    return FeatureGates.checkGate(REQUEST_CREATE_DROPDOWN_INITIAL_OPTIONS_CALL);
};

export const isProformaIsDirtyPreventInvalidStateEnabled = () => {
    return FeatureGates.checkGate('proforma_form_dirty_prevent_invalid_state');
};

export const isFilterQueryErrorFromSentryEnabled = () => {
    return FeatureGates.checkGate(FILTER_QUERY_ERROR_FROM_SENTRY);
};

export const isPragmaticDndMigrationLayerEnabled = () => {
    return FeatureGates.checkGate(PRAGMATIC_DND_MIGRATION_LAYER);
};

export const isHideKbColumnEnabled = () => {
    return FeatureGates.checkGate(HIDE_KB_COLUMN_PROJECT_BREAKDOWN);
};

export const isVsaSettingsPerformanceImprovementsEnabled = () => {
    return FeatureGates.checkGate(VSA_SETTINGS_PERFORMANCE_IMPROVEMENTS);
};

export const isFilterNetworkErrorsWithTraceIdFromSentryEnabled = () => {
    return FeatureGates.checkGate(FILTER_NETWORK_ERRORS_WITH_TRACE_ID);
};

export const isTotalArticleCountEnabled = () => {
    return FeatureGates.checkGate(TOTAL_ARTICLE_COUNT);
};
