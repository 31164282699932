import type { ModalExtension } from '@atlassian/forge-ui';
import type { BridgeConfig } from '@atlassian/forge-ui/dist/types/custom-ui/bridge';
import type { ComponentMap } from '@atlassian/forge-ui/ui';
import type { LicenseDetails } from '@atlassian/forge-ui-types';
import type { FieldValueType } from '@atlassian/help-center-common-component/fields/types';
import type {
    PORTAL_FOOTER_MODULE,
    PORTAL_HEADER_MODULE,
    PORTAL_SUBHEADER_MODULE,
    PROFILE_PANEL_MODULE,
    REQUEST_DETAIL_MAIN_SECTION_MODULE,
    REQUEST_DETAIL_SIDE_SECTION_MODULE,
    REQUEST_VIEW_ACTION_COMPONENT,
    REQUEST_VIEW_ACTION_MODULE,
    USER_MENU_ACTION_COMPONENT,
    USER_MENU_ACTION_SECTION_MODULE,
    PORTAL_REQUEST_CREATE_PROPERTY_PANEL_MODULE,
    HELP_CENTER_LAYOUT_ELEMENT_MODULE,
    CUSTOM_FIELD,
    CUSTOM_FIELD_TYPE,
    UI_MODIFICATIONS,
} from '@atlassian/help-center-common-component/forge-ui/constants';

import type { FetchTFExtensionsData } from 'rest/trusted-fetch-extensions/types';

export type RequestDetailMainSectionModule = typeof REQUEST_DETAIL_MAIN_SECTION_MODULE;
export type RequestDetailSideSectionModule = typeof REQUEST_DETAIL_SIDE_SECTION_MODULE;
export type PortalHeaderModule = typeof PORTAL_HEADER_MODULE;
export type PortalFooterModule = typeof PORTAL_FOOTER_MODULE;
export type PortalSubHeaderModule = typeof PORTAL_SUBHEADER_MODULE;
export type ProfilePanelModule = typeof PROFILE_PANEL_MODULE;
export type UserMenuActionSectionModule = typeof USER_MENU_ACTION_SECTION_MODULE;
export type RequestViewActionModule = typeof REQUEST_VIEW_ACTION_MODULE;
export type RequestCreatePropertyPanelModule = typeof PORTAL_REQUEST_CREATE_PROPERTY_PANEL_MODULE;
export type HelpCenterLayoutElementModule = typeof HELP_CENTER_LAYOUT_ELEMENT_MODULE;
export type CustomFieldModule = typeof CUSTOM_FIELD;
export type CustomFieldTypeModule = typeof CUSTOM_FIELD_TYPE;
export type UiModificationsModule = typeof UI_MODIFICATIONS;

export type UserMenuActionComponentName = typeof USER_MENU_ACTION_COMPONENT;
export type RequestViewActionComponentName = typeof REQUEST_VIEW_ACTION_COMPONENT;

export type ForgeActionComponent = UserMenuActionComponentName | RequestViewActionComponentName;

export type ExtensionPointModule =
    | RequestDetailMainSectionModule
    | RequestDetailSideSectionModule
    | PortalHeaderModule
    | PortalFooterModule
    | PortalSubHeaderModule
    | ProfilePanelModule
    | UserMenuActionSectionModule
    | RequestViewActionModule
    | RequestCreatePropertyPanelModule
    | HelpCenterLayoutElementModule
    | CustomFieldModule
    | CustomFieldTypeModule
    | UiModificationsModule;

export type ForgePageName =
    | 'help_center'
    | 'portal'
    | 'create_request'
    | 'view_request'
    | 'my_requests'
    | 'approvals'
    | 'profile';

export interface ForgePagePanelCommonProps {
    page: ForgePageName;
    portalId?: number;
    requestTypeId?: number;
    requestKey?: string;
    projectId?: number;
    noWrapper?: boolean;
    allowCustomPadding?: boolean;
}

export interface ForgeRunnerProps extends ForgePagePanelCommonProps, Omit<WithExtensionListProps, 'page'> {
    type: PortalHeaderModule | PortalFooterModule | PortalSubHeaderModule | ProfilePanelModule;
}

export interface ForgeRunnerPropsWithExtension extends Omit<ForgeRunnerProps, 'extensions'> {
    extension: Extension;
}

export interface ForgeActionProperties {
    type: RequestViewActionModule | UserMenuActionSectionModule;
    portalId?: number;
    projectId?: number;
    requestTypeId?: number;
    requestKey?: string;
    componentName: ForgeActionComponent;
}

export type ExtensionEnvironment = 'PRODUCTION' | 'STAGING' | 'DEVELOPMENT';

export const ALLOWED: string = 'ALLOWED';
export const BLOCKED: string = 'BLOCKED';

export type ForgeExtensionAppsAllowedOrBlocked = typeof ALLOWED | typeof BLOCKED;

export const EXTENSION = 'extension';

interface GenericExtension<T, P> {
    id: string;
    appVersion?: string;
    installationId: string;
    environmentId: string;
    environmentType: ExtensionEnvironment;
    dataClassificationPolicyDecision?: {
        status: ForgeExtensionAppsAllowedOrBlocked;
    };
    environmentKey: string;
    properties: P;
    type: T;
    requiresUserConsent?: boolean;
    key?: string;
    __typename: 'GQLExtension';
}

/**
 * This is the type of the extension that is returned from the backend API.
 * This backend API implements display conditions and app access rules.
 * We have to migrate all the extensions to this type (while fetching them from that backend API of course).
 */
interface GenericAggExtension<T, P> {
    properties: P;
    type: T;
    appVersion: string;
    consentUrl: string | undefined;
    egress: {
        addresses: string[];
        type: string;
        category?: string;
        inScopeEUD?: boolean;
    }[];
    overrides?: Record<string, boolean>;
    installationConfig?: {
        key: string;
        value: boolean;
    }[];
    environmentId: string;
    environmentKey: string;
    environmentType: ExtensionEnvironment;
    hiddenBy?: 'AppAccessRules' | 'DisplayConditions' | null | undefined;
    id: string;
    installationId: string;
    license: LicenseDetails | null;
    userAccess:
        | {
              hasAccess: boolean;
          }
        | null
        | undefined;
    // Types below should be removed once all the extensions are migrated to the new AGG type
    // It is added for compatibility with the old type and to make AN banner work
    key: string;
    dataClassificationPolicyDecision: {
        status: ForgeExtensionAppsAllowedOrBlocked;
    };
}

export type AggExtension = GenericAggExtension<ExtensionPointModule, object>;

export type ViewportSizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

interface DisplayConditions {
    [key: string]: unknown;
}

interface CommonProps {
    typeId?: string;
    title: string;
    function?: string;
    formatter?: string;
    resource?: string;
    render?: string;
    resolver?: {
        function: string;
    };
    displayConditions?: DisplayConditions;
    viewportSize?: ViewportSizeType;
    pages?: ForgePageName[];
}

export type RequestDetailMainSection = GenericExtension<
    RequestDetailMainSectionModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType }
>;
export type RequestDetailSideSection = GenericExtension<
    RequestDetailSideSectionModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType }
>;

export type PortalHeader = GenericExtension<
    PortalHeaderModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType; pages?: ForgePageName[] }
>;

export type PortalFooter = GenericExtension<
    PortalFooterModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType; pages?: ForgePageName[] }
>;

export type PortalSubHeader = GenericExtension<
    PortalSubHeaderModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType; pages?: ForgePageName[] }
>;

export type ProfilePanel = GenericExtension<
    ProfilePanelModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType; pages?: ForgePageName[] }
>;

export type UserMenuActionSection = GenericExtension<
    UserMenuActionSectionModule,
    CommonProps & { icon?: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType }
>;

export type RequestViewAction = GenericExtension<
    RequestViewActionModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType }
>;

export type RequestCreatePropertyPanel = GenericExtension<
    RequestCreatePropertyPanelModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType }
>;

export type HelpCenterLayoutElement = GenericExtension<
    HelpCenterLayoutElementModule,
    CommonProps & { icon: string; allowMultiple?: boolean; viewportSize?: ViewportSizeType; description?: string }
>;

export type CustomFieldSystemType = 'string' | 'number' | 'user' | 'group' | 'object' | 'datetime' | 'date';
export type CustomFieldCollectionType = 'none' | 'list';
export type RenderType = 'default' | 'native';

interface CustomFieldProps {
    type: CustomFieldSystemType;
    name: string;
    description: string;
    readOnly?: boolean;
    collection?: CustomFieldCollectionType;
    displayConditions?: DisplayConditions;
    view?: {
        experience?: ['portal-view'];
        resource?: string;
        render?: RenderType;
        formatter?: {
            expression: string;
        };
        value?: {
            function: string;
        };
    };
    edit?: {
        resource: string;
        experience?: ['portal-request'];
        isInline?: boolean;
        render?: RenderType;
        validation?: {
            errorMessage: string;
            expression: string;
        };
    };
}

// TempCustomFieldProps should be removed once all the extensions are migrated to the new AGG type
type TempCustomFieldProps = CommonProps & { icon?: string };
export type CustomField = GenericAggExtension<CustomFieldModule, TempCustomFieldProps & CustomFieldProps>;
export type CustomFieldType = GenericAggExtension<CustomFieldTypeModule, TempCustomFieldProps & CustomFieldProps>;

export type UiModifications = GenericAggExtension<UiModificationsModule, CommonProps>;

export type Extension =
    | RequestDetailMainSection
    | RequestDetailSideSection
    | PortalHeader
    | PortalFooter
    | PortalSubHeader
    | ProfilePanel
    | UserMenuActionSection
    | RequestViewAction
    | RequestCreatePropertyPanel
    | HelpCenterLayoutElement
    | CustomField
    | CustomFieldType
    | UiModifications;

export interface Properties {
    key: string;
    value: object;
}
export interface ForgeRequestData {
    key?: string;
    typeId?: number;
    properties?: Properties;
}

interface ForgePortalData {
    id: number;
}

interface GenericRequestDetailForgeExtensionData<T> {
    type: T;
    page?: ForgePageName;
    request: ForgeRequestData;
    portal: ForgePortalData;
}

export type RequestDetailMainSectionExtensionData =
    GenericRequestDetailForgeExtensionData<RequestDetailMainSectionModule>;
export type RequestDetailSideSectionExtensionData =
    GenericRequestDetailForgeExtensionData<RequestDetailSideSectionModule>;
export type PortalRequestCreatePropertyPanelExtensionData =
    GenericRequestDetailForgeExtensionData<RequestCreatePropertyPanelModule>;

interface ForgePagePanelRequestData {
    key?: string;
    typeId?: number;
}

interface ForgePagePanelPortalData {
    id?: number;
}

export interface GenericPagePanelForgeExtensionData<T> {
    type: T;
    page: ForgePageName;
    request?: ForgePagePanelRequestData;
    portal?: ForgePagePanelPortalData;
    accountType?: string;
}

export type FooterExtensionData = GenericPagePanelForgeExtensionData<PortalFooterModule>;
export type HeaderExtensionData = GenericPagePanelForgeExtensionData<PortalHeaderModule>;
export type SubHeaderExtensionData = GenericPagePanelForgeExtensionData<PortalSubHeaderModule>;
export type ProfilePanelExtensionData = GenericPagePanelForgeExtensionData<ProfilePanelModule>;

export type RunnerExtensionData =
    | FooterExtensionData
    | HeaderExtensionData
    | SubHeaderExtensionData
    | ProfilePanelExtensionData;

export interface ForgeActionExtensionData {
    type: RequestViewActionModule | UserMenuActionSectionModule;
    page?: ForgePageName;
    request?: ForgeRequestData;
    portal?: ForgePortalData;
}

export interface ForgeRequestCreatePropertyFields {
    key: string;
    value: object | string;
}

export interface ForgeAppRequestCreatePropertyData {
    data: ForgeRequestCreatePropertyData;
}

export interface ForgeUIKitFormData {
    data: Record<string, unknown>;
    isValid: boolean;
}

export interface ForgeRequestCreateFieldValue {
    value: ForgeUIKitFormData | null;
    updated: boolean;
}

export interface ForgeDocProps {
    fieldValue: ForgeRequestCreateFieldValue;
}

export interface ForgeRequestCreatePropertyData {
    fields: ForgeRequestCreatePropertyFields[];
    isValid: boolean;
}
export interface ForgeRequestCreatePropertyFormData {
    data: ForgeRequestCreatePropertyData;
    extensionId: string;
}

export interface ForgeRequestCreatePropertyState {
    [key: string]: ForgeRequestCreatePropertyData;
}

interface LayoutElementExtensionData {
    type: ExtensionPointModule;
    config?: object;
}

type CustomFieldCommonExtensionData = GenericRequestDetailForgeExtensionData<
    CustomFieldModule | CustomFieldTypeModule
> & {
    fieldType: string;
    fieldId: string;
};
type CustomFieldPortalRequestExtensionData = CustomFieldCommonExtensionData & {
    experience: 'portal-request';
    renderContext: 'portal-request';
    entryPoint: 'edit';
};
type CustomFieldPortalViewExtensionData = CustomFieldCommonExtensionData & {
    experience: 'portal-view';
    renderContext: 'portal-view';
    entryPoint: 'view';
};

export type CustomFieldExtensionData = CustomFieldPortalRequestExtensionData | CustomFieldPortalViewExtensionData;

export type ExtensionData =
    | RunnerExtensionData
    | RequestDetailMainSectionExtensionData
    | RequestDetailSideSectionExtensionData
    | ForgeActionExtensionData
    | PortalRequestCreatePropertyPanelExtensionData
    | LayoutElementExtensionData
    | CustomFieldExtensionData;

export interface CustomFieldExtensionPayload {
    fieldValue: FieldValueType;
}

export type ExtensionPayload = CustomFieldExtensionPayload;

export interface PlatformContextExtensionData {
    page?: ForgePageName;
    portalId?: number;
    requestKey?: string;
    requestTypeId?: number;
}

export interface ForgeDoc {
    children: ForgeDoc[];
    key?: string;
    props?: {
        [key: string]: unknown;
    };
    type: string;
}

export interface ForgeModalContextProviderProps extends WithExtensionListProps {
    type: ExtensionPointModule;
}

export interface ExtensionTitle {
    id: string;
    title: string;
    icon?: string;
}

export type ExtensionMap = Map<string, Extension>;

export type SetExtensionToDisplayType = (extensionId: string) => void;

// eslint-disable-next-line no-shadow
export enum ContextTokenType {
    JSM = 'JSM',
    JIRA = 'JIRA',
}

export interface ContextTokenData {
    extensionId: string;
    token: string;
    expiresAt: number;
}

export interface ForgeModalContextType {
    data: {
        extensions: ExtensionMap;
        extensionToDisplay: string;
        extensionTitles: ExtensionTitle[];
    };
    methods: {
        setExtensionToDisplay: SetExtensionToDisplayType;
    };
}

export interface WithExtensionListProps {
    type?: ExtensionPointModule;
    extensions: Extension[];
    page?: ForgePageName;
    // unique key for requests created on portal
    requestKey?: string;
    projectId?: number;
}

export interface WithBlockedAppsBannerProps {
    showBanner?: (forgePageName: ForgePageName) => void;
    resetBannerState?: (forgePageName: ForgePageName) => void;
}

export interface ResourceContext {
    resourceId?: string;
    resourceType?: string;
}

export interface TFECacheContextType {
    getExtensionsByType: (type: ExtensionPointModule, page?: ForgePageName) => Promise<FetchTFExtensionsData>;

    getExtensionsByTypeV2: (
        type: ExtensionPointModule,
        resourceContext?: ResourceContext,
        page?: ForgePageName
    ) => Promise<FetchTFExtensionsData>;
}

// eslint-disable-next-line no-shadow
export enum AtlassianHelpConfigAccountType {
    ATLASSIAN = 'atlassian',
    CUSTOMER = 'customer',
    ANONYMOUS = 'anonymous',
}

export interface RendererProps {
    extension: Extension;
    extensionData: ExtensionData;
    extensionPayload?: ExtensionPayload;
    getComponents?: (defaults: ComponentMap) => ComponentMap;
    loadingComponent?: JSX.Element;
    onRender?: (forgeDoc: ForgeDoc | void) => void;
    onSubmit?: (forgeDoc: ForgeDoc | void) => void;
    modalExtension?: ModalExtension;
    entryPoint?: string;
    bridge?: BridgeConfig;
    onModalExtensionClose?: () => void;
    customBridgeMethods?: Record<string, (...args: unknown[]) => unknown>;
    onIframeLoad?: () => void;
    isIframeResizable?: boolean;
    isIframeHidden?: boolean;
    onConsentInitialization?: () => void;
    onConsentSuccess?: () => void;
    consentMessage?: string;
    contextTokenType?: ContextTokenType;
}
export type ForgeUIKitRendererProps = RendererProps & {
    getContextToken?: () => Promise<string>;
    contextIds: string[];
    requestKey: string;
    cloudId: string;
    accountId: string;
};
