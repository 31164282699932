/**
 * @generated SignedSource<<776ed451990f62a01914ad41d9b9d424>>
 * @relayHash f6c352f48eeebb85cf0c9fbc89dbddb2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4e8801d6cc9f23bf8354598a09093970032c0e0c52119c549c1b54f759bd1319

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type omnichannelClientConnectionQuery$variables = {
  conversationId: string;
  isEmptyConversation: boolean;
};
export type omnichannelClientConnectionQuery$data = {
  readonly jsmChat?: {
    readonly getWebConversation: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "JsmChatMessageEdge";
        readonly __id: string;
        readonly " $fragmentSpreads": FragmentRefs<"messageRendererFragment">;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type omnichannelClientConnectionQuery = {
  response: omnichannelClientConnectionQuery$data;
  variables: omnichannelClientConnectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "conversationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isEmptyConversation"
  }
],
v1 = {
  "kind": "Variable",
  "name": "conversationId",
  "variableName": "conversationId"
},
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v5 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    },
    {
      "kind": "ScalarField",
      "name": "startCursor"
    }
  ]
},
v6 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "last",
    "value": 1
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "content"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "label"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "omnichannelClientConnectionQuery",
    "selections": [
      {
        "condition": "isEmptyConversation",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JsmChatQuery",
            "kind": "LinkedField",
            "name": "jsmChat",
            "plural": false,
            "selections": [
              {
                "alias": "getWebConversation",
                "args": [
                  (v1/*: any*/)
                ],
                "concreteType": "JsmChatMessageConnection",
                "kind": "LinkedField",
                "name": "__Connection__getWebConversation_connection",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JsmChatMessageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "messageRendererFragment"
                      },
                      (v3/*: any*/),
                      {
                        "concreteType": "JsmChatCreateWebConversationMessage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  },
                  (v5/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "omnichannelClientConnectionQuery",
    "selections": [
      {
        "condition": "isEmptyConversation",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JsmChatQuery",
            "kind": "LinkedField",
            "name": "jsmChat",
            "plural": false,
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JsmChatMessageConnection",
                "kind": "LinkedField",
                "name": "getWebConversation",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JsmChatMessageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "concreteType": "JsmChatCreateWebConversationMessage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "authorType"
                          },
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "action"
                              },
                              {
                                "concreteType": "JsmChatAssistantMessage",
                                "kind": "LinkedField",
                                "name": "assistantMessage",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "pluginInvocation"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "aiMateConversationResponse"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "message_metaData"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "appendices",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isJsmChatConversationAppendixAction"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JsmChatAppendixActionItem",
                                    "kind": "LinkedField",
                                    "name": "options",
                                    "plural": true,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/)
                                    ]
                                  }
                                ],
                                "type": "JsmChatDropdownAppendix"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "fieldId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "requestTypeId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "jiraProjectId"
                                  }
                                ],
                                "type": "JsmChatJiraFieldAppendix"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "concreteType": "JsmChatAppendixActionItem",
                                    "kind": "LinkedField",
                                    "name": "options",
                                    "plural": true,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      {
                                        "concreteType": "JsmChatAppendixRedirectAction",
                                        "kind": "LinkedField",
                                        "name": "redirectAction",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "baseUrl"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "query"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "type": "JsmChatOptionAppendix"
                              },
                              (v4/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v5/*: any*/),
                  (v4/*: any*/)
                ]
              },
              {
                "args": (v6/*: any*/),
                "filters": [
                  "conversationId"
                ],
                "handle": "connection",
                "key": "Connection__getWebConversation",
                "kind": "LinkedHandle",
                "name": "getWebConversation"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4e8801d6cc9f23bf8354598a09093970032c0e0c52119c549c1b54f759bd1319",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "backward",
          "path": [
            "jsmChat",
            "getWebConversation"
          ]
        }
      ]
    },
    "name": "omnichannelClientConnectionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8ddd798333f81ebc8a31a378348e8046";

export default node;
