import React from 'react';
import { isUiModificationsForgeContextTokenEndpointEnabled } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { UI_MODIFICATIONS } from '@atlassian/help-center-common-component/forge-ui/constants';
import { ExtensionPointProvider } from '@atlassian/help-center-common-component/forge-ui/extension-point-provider';
import { Renderer } from '@atlassian/help-center-common-component/forge-ui/renderer';
import { ContextTokenType, type UiModifications } from '@atlassian/help-center-common-component/forge-ui/types';
import { type ForgeAppRendererProps as ForgeAppRendererPropsBase } from '@atlassian/ui-modifications-core/types';

export type ForgeAppRendererProps = ForgeAppRendererPropsBase & { extension: UiModifications };

export const ForgeAppRenderer = (props: ForgeAppRendererProps) => {
    di(Renderer, ExtensionPointProvider);
    const { extension, ...restProps } = props;
    return (
        <ExtensionPointProvider extensionType={UI_MODIFICATIONS}>
            <Renderer
                {...restProps}
                extension={extension}
                contextTokenType={
                    isUiModificationsForgeContextTokenEndpointEnabled() ? ContextTokenType.JIRA : undefined
                }
            />
        </ExtensionPointProvider>
    );
};
